<template>
	<div>
		<div class="row">
			<div class="col-md-6"></div>
			<div class="col-md-6">
				<span class="float-right"
					>Update every:
					<b-form-select
						class="bform"
						v-model="updateEvents.time"
						@change="changeUpdateEvents()"
						:options="timeOptions"
					></b-form-select>
				</span>
			</div>
		</div>
		<div class="card card-custom card-stretch gutter-b mt-5">
			<div class="card-header border-0 pt-3 d-flex align-items-center" style="min-height: 60px">
				<h4>Events</h4>
			</div>
			<div class="card-body">
				<b-table
					striped
					hover
					:items="events"
					:fields="Efields"
					:sort-by.sync="EsortBy"
					:sort-desc.sync="EsortDesc"
					:current-page="EcurrentPage"
					:per-page="perPage"
				>
					<template #cell(unique_id)="data">
						{{ device.networks[data.item.unique_id].NetworkName }}
					</template>
					<template #cell(event_level)="data">
						{{ event_levels[data.item.event_level] }}
					</template>
				</b-table>
			</div>
			<div class="footer" v-if="events.length > 0 && events.length > perPage">
				<b-pagination
					class="pagination"
					v-model="EcurrentPage"
					:total-rows="events.length"
					:per-page="perPage"
				/>
			</div>
		</div>
		<div class="card card-custom card-stretch gutter-b mt-5">
			<div class="card-header border-0 pt-3 d-flex align-items-center" style="min-height: 60px">
				<h4>Conditions</h4>
			</div>
			<div class="card-body">
				<b-table
					striped
					hover
					:items="conditions"
					:fields="Cfields"
					:sort-by.sync="CsortBy"
					:sort-desc.sync="CsortDesc"
					:current-page="CcurrentPage"
					:per-page="perPage"
				>
					<template #cell(unique_id)="data">
						{{ device.networks[data.item.unique_id].NetworkName }}
					</template>
					<template #cell(error_severity)="data"
						>{{ data.item.error_severity.substring(3) }}
					</template>
				</b-table>
			</div>
			<div class="footer" v-if="conditions.length > 0 && conditions.length > perPage">
				<b-pagination
					class="pagination"
					v-model="CcurrentPage"
					:total-rows="conditions.length"
					:per-page="perPage"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['id', 'device'],
	data() {
		return {
			loading: false,
			selectedNetwork: null,
			updateEvents: { time: 0, interval: null },
			eventcountdown: 0,
			networks: [],
			timeOptions: [
				{
					value: 0,
					text: 'Stop',
				},
				{ value: 5, text: '5 seconds' },
				{ value: 30, text: '30 seconds' },
				{ value: 300, text: '5 minutes' },
			],
			events: [],
			Efields: [
				{
					key: 'timestamp',
					label: 'Time',
					sotable: true,
					sortDirection: 'desc',
				},
				{ key: 'unique_id', label: 'Network' },
				{ key: 'event_level', label: 'Event Level' },
				{ key: 'msg', label: 'Event Description' },
			],
			conditions: [],
			Cfields: [
				'timestamp',
				'current_state',
				{ key: 'unique_id', label: 'Network' },
				'occurred_at',
				'error_type',
				'error_severity',
				'reason',
				'error_text',
			],
			perPage: 6,
			EcurrentPage: 1,
			EsortBy: 'timestamp',
			EsortDesc: true,
			CcurrentPage: 1,
			CsortBy: 'timestamp',
			CsortDesc: true,
			event_levels: ['Info', '', '', '', ''],
		};
	},
	methods: {
		changeNetwork(network) {},
		changeUpdateEvents() {
			clearInterval(this.updateEvents.interval);
			if (this.updateEvents.time != 0) {
				this.updateEvents.interval = setInterval(() => {
					this.loading = true;
					this.$http.get(`realtime/${this.id}/event`).then((d) => {
						if (d.data.data != 'no data') {
							for (var i = 0; i < d.data.data.length; i++) {
								if (

									Date.parse(d.data.data[i].timestamp.replace(/-/g, '/')) >
									Date.parse(this.events[0].timestamp.replace(/-/g, '/'))
								) {
									this.events.unshift(d.data.data[i]);
								}
							}
						}
						this.loading = false;
					});
					this.$http.get(`realtime/${this.id}/state`).then((d) => {
						if (d.data.data != 'no data') {
							for (var i = 0; i < d.data.data.length; i++) {
								if (
									Date.parse(data.data[i].timestamp.replace(/-/g, '/')) >
									Date.parse(this.conditions[0].timestamp.replace(/-/g, '/'))
								) {
									this.conditions.unshift(d.data.data[i]);
								}
							}
						}
					});
				}, this.updateEvents.time * 1000);
			}
		},
		getEvents(sec) {
			this.loading = true;
			var get = '';
			if (sec) {
				get = '?sec=' + sec;
			}
			if (this.selectedNetwork) {
				if (get == '') {
					get = '?';
				} else {
					get += '&';
				}
				get += 'unique_id=' + this.selectedNetwork;
			}
			this.$http.get(`realtime/${this.id}/event${get}`).then((d) => {
				if (d.data.data != 'no data') {
					this.events = d.data.data;
				}
				this.loading = false;
			});
			this.$http.get(`realtime/${this.id}/state${get}`).then((d) => {
				if (d.data.data != 'no data') {
					this.conditions = d.data.data;
				}
			});
		},
		updateNetworks(value) {
			if (value && value.networks && this.networks.length == 0) {
				this.networks = [];
				Object.keys(value.networks).forEach((v) => {
					this.networks.push({
						value: v,
						text: value.networks[v].NetworkName,
					});
				});
			}
		},
	},
	beforeDestroy(){
		clearInterval(this.updateEvents.interval);
	},
	created() {
		this.getEvents();
		this.updateNetworks(this.device.networks);
	},
	watch: {
		device: {
			deep: true,
			handler(value) {
				this.updateNetworks(value);
			},
		},
	},
};
</script>

<style>
.bform {
	width: 280px;
}
.pagination {
	float: right;
}
</style>